<div
  class="_x_shadow-cookies _x_mx-auto _x_flex _x_w-full _x_flex-row _x_items-center _x_justify-between _x_bg-white _x_py-4 _x_pl-20 _x_pr-30 sm:_x_bottom-0 sm:_x_mb-15 sm:_x_max-w-309 sm:_x_rounded-8 sm:_x_p-10 sm:_x_shadow-default">
  <div class="_x_flex _x_w-full _x_items-center _x_justify-start _x_gap-4 sm:_x_w-full sm:_x_flex-row _x_pointer-events-auto">
    <p class="_x_font-medium _x_text-2 _x_text-dark sm:_x_text-3">
      <span class="sm:_x_hidden"> ვებსაიტი იყენებს Cookie-ებს. </span>
      <span class="_x_hidden sm:_x_flex"> ვებსაიტი იყენებს Cookie ფაილებს. </span>
    </p>
    <a class="_x_font-bold _x_text-2 _x_text-link-blue sm:_x_text-3" [routerLink]="['static', 'privacy']">
      <span class="sm:_x_hidden"> მეტი </span>
      <span class="_x_hidden sm:_x_flex"> გამოყენების პირობები </span>
    </a>
    <button
      aria-label="Justify"
      (click)="closePopup()"
      class="_x_hidden _x_h-20 _x_min-w-65 _x_items-center _x_justify-center _x_rounded-8 _x_bg-purple-100 _x_px-5 _x_font-medium _x_text-purple _x_transition _x_duration-300 sm:_x_ml-auto sm:_x_block lg:_x_flex">
      <span class="_x_font-bold _x_text-3">თანხმობა</span>
    </button>
    <button
      aria-label="Justify"
      (click)="closePopup()"
      class="_x_ml-auto _x_flex _x_h-16 _x_w-16 _x_items-center _x_justify-center _x_rounded-8 _x_bg-purple-100 _x_font-medium _x_text-purple _x_transition _x_duration-300 sm:_x_hidden">
      <i
        class="_x_align-center _x_icon _x_icon-done-check _x_relative _x_flex _x_h-8 _x_max-h-8 _x_min-h-8 _x_w-8 _x_min-w-8 _x_justify-center _x_text-5"></i>
    </button>
  </div>
</div>
