import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthConfig, LoginOptions, OAuthService } from 'angular-oauth2-oidc';
import { LocalStorageService, OrderingHttp } from 'lib-core';
import { CookieService } from 'ngx-cookie-service';
import { fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { PopupService } from '../shared/components/HOC/popup-overlay';

import { CampaignsModel } from 'projects/cms/src/app/routes/shell/campaigns/campaigns.model';
import { CookieBannerService } from '../layout/cookie-banner/cookie-banner.service';
import { CommonService } from '../services/common.service';
import { ErrorService } from '../services/error.service';
import { FbPixelService } from '../services/fb-pixel.service';
import { OverflowHandlerService } from '../services/overflow-handler.service';
import { ServerTimeService } from '../services/server-time.service';
import { ErrorModalService } from '../shared/components/error-modal-component/error-modal.service';
import { logIn } from '../store/actions/auth.actions';
import { checkLocalBasket, getBasket } from '../store/actions/basket.actions';
import { GetCampaignsAttempt } from '../store/actions/campaigns.actions';
import { setIsAdult, setPreviousUrl } from '../store/actions/layout.actions';
import { GetRibbonAttempt } from '../store/actions/ribbon.actions';
import { getUser } from '../store/actions/user.actions';
import { getWishlistProductIdsAttempt } from '../store/actions/wishlist.actions';
import * as fromAuth from '../store/models/auth.model';
import * as fromGuard from '../store/models/guard.model';
import * as fromLayout from '../store/models/layout.model';
import { ExpressOrderFinancialType } from '../store/models/orders.model';
import * as fromRibbon from '../store/models/ribbon.model';
import { WishlistModel } from '../store/models/wishlist.model';

import { getComparison } from '../store/actions/comparison.actions';

import { getUserStatus } from '../store/reducers/auth.reducer';
import { getSidebar } from '../store/reducers/layout.reducer';
import { selectRibbonActiveState } from '../store/reducers/ribbon.reducer';
import { getUserData } from '../store/reducers/user.reducer';

import { MixpanelService } from 'projects/lib-core/src/lib/services/mixpanel.service';
import { AddressService } from '../services/address.service';
import { ConfirmationDialogService } from '../services/confirmation-dialog.service';
import { ExpressService } from '../services/express.service';
import { GlobalModalService } from '../services/global-modal-service';
import { OrderDebtService } from '../services/order-debt.service';
import { SmartBannerService } from '../services/smart-banner.service';
import { ChatService } from '../services/chat.service';
import { UserTypeService } from '../services/user-type.service';
import { IsMobileService } from '../services/is-mobile.service';
declare let gtag: Function;

@Component({
  selector: 'app-pages-wraper',
  templateUrl: './pages-wraper.component.html',
  styleUrls: ['./pages-wraper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagesWraperComponent implements OnInit, OnDestroy, AfterViewInit {
  images: Array<any> = [];
  layoutStatus$: Observable<fromLayout.SidebarModel> = this.store.pipe(select(getSidebar));
  lastUrlLength: number;
  lastParams: string;
  mobileOutlet: boolean = true;
  mobileOutletMob: boolean = true;
  isBasket: boolean = false;
  userStatus$: Observable<any> = this.store.pipe(select(getUserStatus));
  public isChatOpen: boolean = false;
  private current;
  private previousUrl: string = '/';
  private currentUrl: string = '/';
  private previousParams: any = {};
  private currentParams: any = {};
  private destroy$: Subject<boolean> = new Subject<boolean>();
  timeoutIds: any[] = [];
  public selectRibbonActiveState$: Observable<any> = this.store.pipe(select(selectRibbonActiveState));
  orderWithDebt;
  public showChatLogo: boolean = false;
  constructor(
    private store: Store<
      | fromAuth.AuthModel
      | fromLayout.SidebarModel
      | fromGuard.GuardModel
      | fromRibbon.RibbonModel
      | WishlistModel
      | CampaignsModel
    >,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private mixpanel: MixpanelService,
    private oauthService: OAuthService,
    private errorService: ErrorService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private commonService: CommonService,
    private fbPixelService: FbPixelService,
    private orderingService: OrderingHttp,
    private expressService: ExpressService,
    private addressService: AddressService,
    private userTypeService: UserTypeService,
    private serverTimeService: ServerTimeService,
    private errorModalService: ErrorModalService,
    private localStorageService: LocalStorageService,
    private cookieBannerService: CookieBannerService,
    private overflowHandlerService: OverflowHandlerService,
    private confirmationDialogService: ConfirmationDialogService,
    public popupService: PopupService,
    public orderDebtService: OrderDebtService,
    public smartBannerService: SmartBannerService,
    public globalModalService: GlobalModalService,
    public chatService: ChatService,
    public isMobileService: IsMobileService
  ) {
    this.images = [
      { name: 'http://lorempixel.com/640/480/animals/' },
      { name: 'http://lorempixel.com/640/480/abstract/' },
      { name: 'http://lorempixel.com/640/480/business/' },
      { name: 'http://lorempixel.com/640/480/cats/' },
      { name: 'http://lorempixel.com/640/480/city/' },
      { name: 'http://lorempixel.com/640/480/food/' },
      { name: 'http://lorempixel.com/640/480/nightlife/' },
      { name: 'http://lorempixel.com/640/480/fashion/' },
      { name: 'http://lorempixel.com/640/480/people/' },
      { name: 'http://lorempixel.com/640/480/nature/' },
      { name: 'http://lorempixel.com/640/480/sports/' },
      { name: 'http://lorempixel.com/640/480/transport/' }
    ];
    this.translate.setDefaultLang('ge');
    this.expressService.setDarkStoreId();
    this.initOAuth();
    this.initializeErrors();
  }

  private initializeErrors() {
    this.errorService
      .getErrors()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.errorModalService.showModal();
      });
  }

  ngOnInit(): void {
    this.store.dispatch(GetRibbonAttempt());
    this.serverTimeService.getServerTime();
    if (isPlatformBrowser(this.platformId)) {
      if (sessionStorage.getItem('isAdult')) {
        this.store.dispatch(setIsAdult({ isAdult: sessionStorage.getItem('isAdult') === 'true' }));
      }
    }
    const scrollTopFunc = () => {
      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo(0, 0);
      }
    };

    this.userStatus$.pipe(takeUntil(this.destroy$)).subscribe(status => {
      if (status) {
        this.getUserDebt();
      }
    });

    this.cookieBannerService.detectCookieBannerFirstLoadState();

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.expressService.removeSearchCase();
        this.isBasket = false;
        const baseUrl = this.router.parseUrl(this.router.url).root.children;
        let url = '/';
        baseUrl.primary &&
          baseUrl.primary.segments.length &&
          baseUrl.primary.segments.forEach((item, index) => {
            index && (url += '/');
            url += item.path;
          });
        this.previousUrl = this.currentUrl;
        this.previousParams = this.currentParams;
        this.store.dispatch(
          setPreviousUrl({
            previousUrl: this.previousUrl,
            queryParams: this.previousParams
          })
        );
        this.currentUrl = url;
        this.currentParams = this.route.snapshot.queryParams;
        if (Object.keys(baseUrl) && Object.keys(baseUrl).length) {
          if (baseUrl.primary.segments[0].path === 'wishlist') {
            scrollTopFunc();
          }
          if (baseUrl.primary.segments[0].path === 'sellers') {
            scrollTopFunc();
          }
          if (baseUrl.primary.segments[0].path === 'product') {
            scrollTopFunc();
          }
          if (baseUrl.primary.segments[0].path === 'user') {
            scrollTopFunc();
          }
          if (baseUrl.primary.segments[0].path === 'checkout') {
            scrollTopFunc();
          }
          if (baseUrl.primary.segments[0].path === 'search') {
            scrollTopFunc();
          }
          if (baseUrl.primary.segments[0].path === 'trade-in') {
            scrollTopFunc();
          }
          if (baseUrl.primary.segments[0].path === 'static') {
            scrollTopFunc();
          }
          if (baseUrl.primary.segments[0].path === 'comparison') {
            scrollTopFunc();
          }
          if (baseUrl.primary.segments[0].path === 'catalog') {
            const paramsString = baseUrl.primary.segments.map(item => item.path).join('');
            if (this.lastUrlLength !== baseUrl.primary.segments.length || this.lastParams !== paramsString) {
              this.timeoutIds.push(setTimeout(() => scrollTopFunc(), 0));
              this.lastUrlLength = baseUrl.primary.segments.length;
              this.lastParams = paramsString;
            }
          }
          if (baseUrl.primary.segments[0].path === 'seller') {
            this.timeoutIds.push(setTimeout(() => scrollTopFunc(), 0));
          }
          if (baseUrl.primary.segments[0].path === 'basket') {
            this.isBasket = true;
            scrollTopFunc();
          }
        } else {
          scrollTopFunc();
        }
        baseUrl.primary && baseUrl.primary.segments
          ? (this.lastUrlLength = baseUrl.primary.segments.length)
          : (this.lastUrlLength = 0);
        if (isPlatformBrowser(this.platformId)) {
          gtag({
            event: 'gtm.onRoute',
            page_path: event.urlAfterRedirects
          });
          this.fbPixelService.pageView();
        }
      }
    });
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (res.current && this.current) {
        if (this.current !== res.current) {
          scrollTopFunc();
          this.current = res.current;
        }
      }
    });
    this.layoutStatus$.pipe(takeUntil(this.destroy$)).subscribe(status => {
      if (status.burgerStatus || status.mobileFilter) {
        if (isPlatformBrowser(this.platformId)) {
          this.overflowHandlerService.hide();
        }
      } else {
        if (isPlatformBrowser(this.platformId)) {
          this.overflowHandlerService.show();
        }
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      this.mobileOutlet = sessionStorage.getItem('infoMobile') !== 'true';
      this.mobileOutletMob = sessionStorage.getItem('infoMobile') !== 'true';
      sessionStorage.setItem('infoMobile', 'false');
    }

    if (isPlatformBrowser(this.platformId)) {
      fromEvent(window, 'scroll')
        .pipe(debounceTime(100), takeUntil(this.destroy$))
        .subscribe(() => {
          this.mobileOutletMob = true;
          this.cdr.markForCheck();
        });
    }

    if (
      isPlatformBrowser(this.platformId) &&
      !window.location.href.includes('checkout/success') &&
      !window.location.href.includes('checkout/check') &&
      !window.location.href.includes('checkout/processing')
    ) {
      this.commonService.clearOrderIdsFromLocalStorage();
    }
  }
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.handleChatScrollIssues();
      this.listenToChatState();
      setTimeout(() => {
        this.showChatLogo = true;
        this.cdr.detectChanges();
      }, 2000);
    }
  }

  handleChatScrollIssues(): void {
    //enabling/disabling overflow when user resizes window size
    this.isMobileService.isChatFullScreen.pipe(takeUntil(this.destroy$)).subscribe((res: boolean) => {
      if (!res) {
        this.overflowHandlerService.show();
      } else {
        this.isChatOpen ? this.overflowHandlerService.hide() : this.overflowHandlerService.show();
      }
    });
  }
  private listenToChatState(): void {
    this.chatService
      .isChatOpen$()
      .pipe(
        distinctUntilChanged((prev, curr) => prev == curr),
        takeUntil(this.destroy$)
      )
      .subscribe((res: boolean) => {
        this.isChatOpen = res;
        if (this.isMobileService.isChatFullScreen.value) {
          this.isChatOpen ? this.overflowHandlerService.hide() : this.overflowHandlerService.show();
        }
        this.cdr.detectChanges();
      });
  }
  initOAuth(): void {
    this.mixpanel.init();
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const userType = localStorage.getItem('user-type');

    const authConfig: AuthConfig = this.userTypeService.isUserLegalEntity
      ? {
          issuer: environment.juridicalIssuerUrl,
          loginUrl: environment.juridicalIssuerUrl + '/connect/authorize',
          logoutUrl: environment.juridicalIssuerUrl + '/connect/endsession',
          tokenEndpoint: environment.juridicalIssuerUrl + '/connect/token',
          userinfoEndpoint: environment.juridicalIssuerUrl + '/connect/userinfo',
          clientId: 'dev',
          dummyClientSecret: 'secret',
          scope: 'offline_access openid',
          redirectUri: window.location.href,
          requireHttps: false,
          oidc: this.cookieService.get('oidc') == 'true',
          silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html'
        }
      : {
          issuer: environment.issuerUrl,
          loginUrl: environment.issuerUrl + '/connect/authorize',
          logoutUrl: environment.issuerUrl + '/connect/endsession',
          tokenEndpoint: environment.issuerUrl + '/connect/token',
          userinfoEndpoint: environment.issuerUrl + '/connect/userinfo',
          redirectUri: window.location.href,
          clientId: 'dev',
          scope: 'identity offline_access openid email profile phone address',
          dummyClientSecret: 'secret',
          requireHttps: false,
          oidc: this.cookieService.get('oidc') == 'true',
          silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html'
        };

    if (!userType) {
      localStorage.setItem('user-type', 'physical');
    }

    this.oauthService.configure(authConfig);
    this.userTypeService.getUserType();
    let loginOptions = new LoginOptions();
    loginOptions.disableOAuth2StateCheck = true;
    this.oauthService.setStorage(isPlatformBrowser(this.platformId) ? localStorage : null);
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      if (this.oauthService.hasValidAccessToken()) {
        this.oauthService.loadUserProfile().then((res: any) => {
          let authMethod = localStorage.getItem('loginMethod');

          {
            this.store
              .pipe(select(getUserData))
              .pipe(takeUntil(this.destroy$))
              .subscribe(userData => {
                if (userData?.id) {
                  this.mixpanel.authUser(userData, authMethod);
                }
              });
          }
          this.store.dispatch(logIn({ authStatus: true }));
          this.store.dispatch(getUser());
          this.store.dispatch(checkLocalBasket());
          this.store.dispatch(getBasket());
          this.store.dispatch(getWishlistProductIdsAttempt({}));
          this.store.dispatch(getComparison());

          if (isPlatformBrowser(this.platformId)) {
            this.oauthService.silentRefreshRedirectUri = window.location.origin + '/silent-refresh.html';
          }

          this.oauthService
            .silentRefresh()
            .then(info => {
              console.debug('refresh ok', info);
              const authSuccessRedirectUrl = this.localStorageService.getValue('authSuccessRedirectUrl');

              if (authSuccessRedirectUrl) {
                this.localStorageService.removeItem('authSuccessRedirectUrl');
                const redirestUrlTree = this.router.parseUrl(authSuccessRedirectUrl);
                this.router.navigateByUrl(redirestUrlTree);
              }
            })
            .catch(err => console.error('refresh error', err));
        });
        this.oauthService.setupAutomaticSilentRefresh({}, 'access_token', true);
        this.oauthService.events.pipe(takeUntil(this.destroy$)).subscribe(e => {
          if (e.type == 'token_received' && this.localStorageService.getValue('isSocLogin') == 'true') {
            this.localStorageService.removeItem('isSocLogin');
            window.location.reload();
          }
        });
      } else {
        this.localStorageService.removeItem('access_token');
        localStorage.removeItem('loginMethod');
        this.mixpanel.reset();
      }
    });
  }

  get showErrorModal(): Observable<boolean> {
    return this.errorModalService.isErrorModalOpen;
  }

  get showCookieBannerModal(): Observable<boolean> {
    return this.cookieBannerService.showCookieBannerPopup;
  }

  get showConfirmationDialog(): boolean {
    return this.confirmationDialogService.isConfirmationDialogOpen;
  }
  get isAddressFormModalOpen(): boolean {
    return this.addressService.isAddressFormModalOpen;
  }

  get isAddressListingModalOpen(): boolean {
    return this.addressService.isAddressListingModalOpen;
  }

  getUserDebt() {
    this.orderingService
      .getUserDebt()
      .pipe(takeUntil(this.destroy$))
      .subscribe(order => {
        if (
          order?.userOrderFinancial &&
          order.userOrderFinancial?.orderFinancialType === ExpressOrderFinancialType.Dept
        ) {
          this.orderDebtService.userHasDebt.next(true);
          this.orderWithDebt = order;
        }
      });
  }

  get isDifferentSpacePage() {
    return this.router.url === '/voucher' || this.router.url.includes('popular');
  }

  get isProductPage() {
    return this.router.url.includes('product/');
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.timeoutIds.forEach(id => clearTimeout(id));
  }
}
