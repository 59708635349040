<!--
  ეს დივი გვჭირდება, რომ დავრაპოს ჰედერი და როუტერ ჰოლდერი
  და ფუტერი ჩააგდოს ყოველთვის დაბლა.
 -->
<div>
  <app-error-modal *ngIf="showErrorModal | async"></app-error-modal>
  <app-notification-popup-main></app-notification-popup-main>
  <div
    class="_x_min-h-[270px] sm:_x_min-h-[224px] md:_x_min-h-[144px]"
    [ngClass]="{
      '!_x_min-h-[222px] md:_x_min-h-[124px]': !(selectRibbonActiveState$ | async),
      'md:_x_min-h-[34]': !(userStatus$ | async) && isBasket,
      '!_x_min-h-[347px] sm:!_x_min-h-[300px]':
        (smartBannerService.openSmartBanner$ | async) && !smartBannerService.isFromSafari
    }">
    <app-header></app-header>
  </div>
  <app-toaster></app-toaster>
  <app-global-modal *ngIf="globalModalService.component$ | async"></app-global-modal>

  <div id="router-holder" class="_x_mt-8" [ngClass]="{ 'md:!_x_mt-0': isDifferentSpacePage }">
    <router-outlet></router-outlet>
  </div>

  <app-address-listing *ngIf="isAddressListingModalOpen"></app-address-listing>
  <app-address-form *ngIf="isAddressFormModalOpen"></app-address-form>
  <app-order-debt
    *ngIf="orderWithDebt && orderDebtService.isOrderDebtModalOpen"
    [orderWithDebt]="orderWithDebt"></app-order-debt>
  <app-debt-payment-modal *ngIf="orderDebtService.isPaymentModalOpen"></app-debt-payment-modal>
  <app-confirmation-dialog *ngIf="showConfirmationDialog"></app-confirmation-dialog>
  <app-sidebar *ngIf="(layoutStatus$ | async).burgerStatus"></app-sidebar>
  <app-popup-overlay></app-popup-overlay>
  <app-backdrop></app-backdrop>

  <div class="fb-customerchat" attribution="setup_tool" page_id="211265162810563" greeting_dialog_display="icon"></div>
</div>
<app-footer></app-footer>

<div
  *ngIf="isChatOpen"
  class="_x_fixed _x_inset-0 _x_z-[49] _x_h-full _x_w-full _x_bg-white"
  [ngClass]="{ _x_hidden: !isMobileService.isChatFullScreen.value }"></div>

<div
  [ngClass]="{ '!_x_bottom-[69px] md:_x_bottom-0': isProductPage }"
  class="_x_pointer-events-none _x_fixed _x_bottom-[0px] _x_left-0 _x_right-0 _x_z-[49]">
    <div class="_x_mb-8 _x_flex _x_justify-end _x_pr-8 sm:_x_absolute sm:_x_bottom-8 sm:_x_right-0 sm:_x_mb-0">
      <button
        *ngIf="(!isChatOpen || !isMobileService.isChatFullScreen.value) && showChatLogo"
        type="button"
        (click)="chatService.toggleChat()"
        class="_x_pointer-events-auto _x_h-28 _x_w-28 sm:_x_bottom-5">
        <img src="../../assets/img/chat/chat.svg" class="_x_h-full _x_w-full" alt="chat icon" />
      </button>
    </div>

  <app-cookie-banner *ngIf="showCookieBannerModal | async"></app-cookie-banner>
</div>
