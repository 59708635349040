import { Injectable } from '@angular/core';
import { OrderingHttp } from 'lib-core';
import { ShippingAddressInteface } from 'projects/web/src/app/store/models/addresses.model';
import { ShippingOptionType } from 'projects/web/src/app/store/models/orders.model';
import { BehaviorSubject, Observable, Subject, map, of, shareReplay, takeUntil } from 'rxjs';

export interface pickUpPointsLocationsForCheckout {
  isEnabled: boolean;
  pickupPoints: [];
}

@Injectable({
  providedIn: 'root'
})
export class PickUpService {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private _basketProductIdsUnfiltered: number[] = [];
  private _pickUpLocations: any = [];
  private _pickUpLocations$ = new BehaviorSubject<ShippingAddressInteface[]>(null);
  private _pickUpLocationCities$ = new BehaviorSubject<any>(null);
  private _pickUpLocationAvailable: boolean = false;
  private _pickUpLocationAvailable$ = new BehaviorSubject<boolean>(false);
  private _pickUpLocationEnabledMainController$ = new BehaviorSubject<boolean>(false);
  private _pickUpLocationEnabledMainController: boolean = false;
  private _pickUpLocationCities: [] = null;
  private _chosenPickUpCity: any = null;
  private _chosenPickUpCity$ = new BehaviorSubject<boolean>(false);
  public isPickupLoading = true;

  constructor(private orderingHttp: OrderingHttp) {}

  getPickUpPointLocations(productIds) {
    this.isPickupLoading = true;
    this.orderingHttp
      .getPickUpPointsLocationsForCheckout({ productIds })
      .pipe(takeUntil(this.destroy$), shareReplay(1))
      .subscribe({
        next: (pickUpPointsLocationsForCheckout: pickUpPointsLocationsForCheckout) => {
          this.isPickupLoading = false;

          if (!pickUpPointsLocationsForCheckout.isEnabled) {
            this.setpickUpLocationEnabledMainController(false);
            return;
          }
          this.setpickUpLocationEnabledMainController(true);
          const pickUpPoints = pickUpPointsLocationsForCheckout.pickupPoints;
          this.setPickUpPointsAvailable(!!pickUpPoints?.length);
          this.setPickUpLocations(pickUpPoints);
          this.extractCities(pickUpPoints);
          if (!this._chosenPickUpCity) {
            this.setDefaultPickUpCity();
          }
        },
        error: err => {
          this.isPickupLoading = false;
          console.error('error occurred: ' + err);
        }
      });
  }

  private setPickUpPointsAvailable(value): void {
    this._pickUpLocationAvailable = value;
    this._pickUpLocationAvailable$.next(value);
  }

  private extractCities(locations) {
    const transformedLocations = locations.map(location => ({
      id: location.id,
      caption: location.caption,
      sortIndex: location.sortIndex,
      description: location.caption,
      isSelected: location.isSelected,
      shippingOptionId: location.pickupPoints?.[0]?.shippingOptionId
    }));
    this._pickUpLocationCities = transformedLocations;
    this._pickUpLocationCities$.next({
      data: transformedLocations,
      totalCount: transformedLocations.length
    });
  }

  private setDefaultPickUpCity() {
    const defaultPickUpCity = this._pickUpLocationCities?.find((location: any) => {
      return location.isSelected;
    });
    if (defaultPickUpCity) {
      this.setChosenCity(defaultPickUpCity);
    }
  }

  getpickUpLocationAvailable(): boolean {
    return this._pickUpLocationAvailable;
  }

  getpickUpLocationAvailable$(): Observable<boolean> {
    return this._pickUpLocationAvailable$.asObservable();
  }

  getpickUpLocationEnabledMainController$(): Observable<boolean> {
    return this._pickUpLocationEnabledMainController$.asObservable();
  }

  getpickUpLocationEnabledMainController(): boolean {
    return this._pickUpLocationEnabledMainController;
  }

  private setpickUpLocationEnabledMainController(value): void {
    this._pickUpLocationEnabledMainController = value;
    this._pickUpLocationEnabledMainController$.next(value);
  }

  private setPickUpLocations(value): void {
    this._pickUpLocations = value;
    this._pickUpLocations$.next(value);
  }

  getChosenCity(): any {
    return this._chosenPickUpCity;
  }

  getChosenCity$(): Observable<any> {
    return this._chosenPickUpCity$.asObservable();
  }

  public setChosenCity(value): void {
    this._chosenPickUpCity = value;
    this._chosenPickUpCity$.next(value);
  }

  getpickUpLocations(): ShippingAddressInteface[] {
    return this._pickUpLocations;
  }

  getpickUpLocations$(): Observable<ShippingAddressInteface[]> {
    return this._pickUpLocations$.asObservable();
  }

  getpickUpLocationsById$(pickupPointId): Observable<ShippingAddressInteface[]> {
    const location = this._pickUpLocations.find(location => location.id === pickupPointId);
    const modifiedPickupPoints = location
      ? location.pickupPoints.map(pickupPoint => ({
          ...pickupPoint,
          address: pickupPoint.addressDisplayName
        }))
      : [];
    return of(modifiedPickupPoints);
  }

  getpickUpLocationsById(pickupPointId): ShippingAddressInteface[] {
    const location = this._pickUpLocations.find(location => location.id === pickupPointId);
    const modifiedPickupPoints = location
      ? location.pickupPoints.map(pickupPoint => ({
          ...pickupPoint,
          address: pickupPoint.addressDisplayName
        }))
      : [];
    return modifiedPickupPoints;
  }

  getPickUpLocationsCities = (state: any): Observable<any[]> => {
    return this._pickUpLocationCities$.asObservable();
  };

  public getPickUpLocationCityById = (id): Observable<any> => {
    return of(this._pickUpLocationCities.find((location: any) => location.id === id));
  };

  setbasketProductIdsUnfiltered(value: number[]): void {
    this._basketProductIdsUnfiltered = value;
    this.getPickUpPointLocations(this._basketProductIdsUnfiltered);
  }

  onDestroy(): void {
    this.setChosenCity(null);
  }
}
